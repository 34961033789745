// import React from 'react'

// TODO: update to allow more variants, such as off-host and on-host
export const isLocalDev = process.env.NODE_ENV === 'development'

const config = {
  // note: Lane was used in logging events, but if we're not going to use it,
  // we should remove it. -BJG
  // lane: "p",
  build: "202411301011",
  // backend api
  app: "https://api.revenant.studio",
  // frontend website
  web: "https://revenant.studio",
  // public CDN
  pub: "https://i.revenant.studio/",
  ws: '/socket',
  graphql: '/graphql', // @GRAPHQL@
  authapi: '/authx/v1/api/', // @AUTHAPI@
  name: 'default',
  nameAn: 'default',
  nameShort: 'default',
  nameLegal: 'default',
  gaKey: 'default', // @GAKEY@
  siteLogo: undefined,
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '370077008372-k4fvmcb357sdjjeu8q8hdnui00aphitq.apps.googleusercontent.com'
    }
  },
  // the above settings are overridden by site-specific runtime configs which
  // come in from `/assets/config/config.js` which is loaded via the <head>
  // defined in the public/index.html file; which is loaded and put into
  // window.config
  ...window.config
}

export default config
