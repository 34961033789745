import React, { Suspense, lazy, useContext } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'

import IdentifiedUser from 'tools/IdentifiedUser'
import { LoadingOverlay } from 'tools/Loading'

import Signon, { Signout } from 'components/Signon'
import GlobalContext from 'reducer/global'
import { useTheme } from 'reducer/global/hooks'

import paths from 'app/codex/paths'
import 'app/codex/style.scss'

const Home = lazy(() => import('app/codex/Home'))
const User = lazy(() => import('components/User'))
const ChangePassword = lazy(() => import('components/User/ChangePassword/Public'))
const PublicUser = lazy(() => import('components/User/Display/Public'))
const ReturnCode = lazy(() => import('components/ReturnCode'))
const AdminSummary = lazy(() => import('components/Admin/Summary'))
const AdminUser = lazy(() => import('components/Admin/User'))
const AdminFile = lazy(() => import('components/Admin/File'))
const Doc0 = lazy(() => import('components/Doc0'))
const PageView = lazy(() => import('components/Doc0/Page/View'))
const PageEdit = lazy(() => import('components/Doc0/Page/Edit'))
const TableView = lazy(() => import('components/Doc0/Table/View'))
const TableEdit = lazy(() => import('components/Doc0/Table/Edit'))
const Help = lazy(() => import('app/libreon/Root/Router/Static/Help'))

const LOADING = <LoadingOverlay />

export function App({ Root }) {
  const [state] = useContext(GlobalContext)

  // useSignon()
  useTheme()

  // <Route path={paths.home}>
  //   <Suspense fallback={LOADING}>
  //     <Home />
  //   </Suspense>
  // </Route>
  return (
    <ApolloProvider client={state.apollo}>
      <Router>
        <Root>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={LOADING}>
                <Home />
              </Suspense>
            </Route>
            <Route path={paths.userRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <User />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.userPublicRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <PublicUser />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.doc.pageRoute}>
              <Suspense fallback={LOADING}>
                <Doc0 Child={PageView} />
              </Suspense>
            </Route>
            <Route path={paths.doc.tableRoute}>
              <Suspense fallback={LOADING}>
                <Doc0 Child={TableView} />
              </Suspense>
            </Route>
            <Route path={paths.doc.tableEditRoute}>
              <Suspense fallback={LOADING}>
                <Doc0 Child={TableEdit} />
              </Suspense>
            </Route>
            <Route path={paths.doc.pageEditRoute}>
              <Suspense fallback={LOADING}>
                <Doc0 Child={PageEdit} />
              </Suspense>
            </Route>
            <Route path={paths.signon}>
              <Signon />
            </Route>
            <Route path={paths.signout}>
              <Signout />
            </Route>
            <Route path={paths.returnCodeRoute}>
              <Suspense fallback={LOADING}>
                <ReturnCode />
              </Suspense>
            </Route>
            <Route path={paths.admin.userRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminUser />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admin.file}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminFile />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admin.summaryRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminSummary />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.passwordRoute}>
              <Suspense fallback={LOADING}>
                <ChangePassword />
              </Suspense>
            </Route>
            <Route path={paths.help}>
              <Help />
            </Route>
          </Switch>
        </Root>
      </Router>
    </ApolloProvider>
  )
}
//
//
// <Route path="/format">
//   <IdentifiedUser>
//     <Suspense fallback={LOADING}>
//       <FormatTest />
//     </Suspense>
//   </IdentifiedUser>
// </Route>
// <Route path="/err">
//   <IdentifiedUser>
//     <Suspense fallback={LOADING}>
//       <ShowError />
//     </Suspense>
//   </IdentifiedUser>
// </Route>

export default App
