import { R_SIGNON, signonInitialState } from 'utils/signon'

import { resetGlobalState } from 'reducer/global'

export function signonReducer(state, type, action) {
  switch (type) {
    case R_SIGNON.SIGNING_IN:
      return {
        ...state,
        signon: { ...signonInitialState(), handshaking: true, error: undefined }
      }

    case R_SIGNON.SIGN_OUT:
      return resetGlobalState(state)

    case R_SIGNON.ERROR_CLEAR:
      return { ...state, signon: { ...state.signon, error: undefined } }

    case R_SIGNON.ERROR_REDIRECT:
      return {
        ...state,
        signon: {
          ...state.signon,
          handshaking: false,
          sub: undefined,
          reload: state.signon.reload + 1,
          error: action.value
        }
      }

    case R_SIGNON.ERROR:
      return {
        ...state,
        signon: { ...state.signon, handshaking: false, error: action.value }
      }

    case R_SIGNON.SIGNED_IN:
      return {
        ...state,
        signon: {
          ...state.signon,
          reload: state.signon.reload + 1,
          sub: action.sub,
          handshaking: false,
          error: undefined
        }
      }

    case R_SIGNON.MERGE:
      return { ...state, signon: { ...state.signon, ...action.value } }

    case R_SIGNON.RELOAD_USER: {
      const reload = state.signon.reload + 1
      return { ...state, signon: { ...state.signon, reload } }
    }

    case R_SIGNON.SIGNIN_TIMEOUT:
      if (state.signon.handshaking) {
        return {
          ...state,
          signon: {
            ...state.signon,
            error:
              'Timeout waiting for auth response, Sorry! (check privacy blockers)',
            handshaking: false
          }
        }
      }
      return state

    default:
      throw new Error(`signon - no such action.type: ${type}!`)
  }
}
