// while we could use interpolation for more editability, this is more efficient

const paths = {
  admin: {
    file: '/adm/file',
    summary: '/adm/summary',
    summaryRoute: '/adm/summary/:tab?',
    user: '/adm/user',
    userRoute: '/adm/user'
  },
  doc: {
    tableEdit: '/adm/table/',
    tableEditRoute: '/adm/table/:site/:table',
    tableRoute: '/t/:site?/:table?/:row?/:args?',
    tableView: '/t/',
    pageEdit: '/adm/page/',
    pageEditRoute: '/adm/page/:site/:page',
    pageRoute: '/d/:site?/:page?',
    pageView: '/d/'
  },
  firstTime: '/prefs/_/subs',
  home: '/',
  password: '/password/',
  passwordRoute: '/password/:targetId?/:code?',
  preferences: '/prefs/_/profile',
  returnCode: '/c',
  returnCodeRoute: '/c/:action?/:shortId?',
  signin: '/signin',
  signon: '/signon',
  signout: '/signout',
  user: '/prefs',
  userPublic: '/u',
  userPublicRoute: '/u/:itemId?',
  userRoute: '/prefs/:itemId?/:tab?/:code?'
}

export default paths
